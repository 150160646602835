import react, {useState} from "react";
import './index.scss'
import emailjs from 'emailjs-com';
import LogoSVG from '../../img/nuevoLogo.svg'
import LogoFooterSVG from '../../img/logoFooterSVG.svg'

import LogoWhat from '../../landing/logoWhats.svg'
import LogoWeb from '../../landing/logoWeb.svg'



import {Link, Redirect} from 'react-router-dom'

import { History } from "history";

//import LogoMobil from '../../landing/LogoMobil.svg'

const axios = require('axios')

const LogoFooter = require('../../img/logofooter.png')
const Icono = require('../../img/icono.png')


const Flechas = require('../../landing/flechas.png')


const LogoDesktop = require('../../landing/logo.svg')




const Home = () =>{


    const[text,setText] = useState('ELIJE LAS ZONAS DE TU INTERES ')

    const[zonatext, setZonatext] = useState('')

    const[seleccionUno,setSeleccionUno] = useState('pointOne')
    const[seleccionDos,setSeleccionDos] = useState('pointTwo')
    const[seleccionTres,setSeleccionTres] = useState('pointThree')
    const[seleccionCuatro,setSeleccionCuatro] = useState('pointFour')
    const[seleccionCinco,setSeleccionCinco] = useState('pointFive')
    const[seleccionSeis,setSeleccionSeis] = useState('pointSix')
    const[seleccionSiete,setSeleccionSiete] = useState('pointSeven')



    const[selectOptionUno, setSelectOptionUno]=useState('radioButton')
    const[selectOptionDos, setSelectOptionDos]=useState('radioButton')
    const[selectOptionTres, setSelectOptionTres]=useState('radioButton')
    const[selectOptionCuatro, setSelectOptionCuatro]=useState('radioButton')
    const[selecttext, setSelectText]=useState('')

    function OpcionSeleccionada(dato){
        console.log(dato)
        if(dato=='Reducir manchas'){
            setSelectText(dato)
            setSelectOptionUno('radioButtonSelected')
            setSelectOptionDos('radioButton')
            setSelectOptionTres('radioButton')
            setSelectOptionCuatro('radioButton')
        }else if(dato=='Quitar líneas de expresión'){
            setSelectText(dato)
            setSelectOptionUno('radioButton')
            setSelectOptionDos('radioButtonSelected')
            setSelectOptionTres('radioButton')
            setSelectOptionCuatro('radioButton')
        }else if(dato=='Eliminar ojeras'){
            setSelectText(dato)
            setSelectOptionUno('radioButton')
            setSelectOptionDos('radioButton')
            setSelectOptionTres('radioButtonSelected')
            setSelectOptionCuatro('radioButton')
        }else if(dato=='Otros'){
            setSelectText(dato)
            setSelectOptionUno('radioButton')
            setSelectOptionDos('radioButton')
            setSelectOptionTres('radioButton')
            setSelectOptionCuatro('radioButtonSelected')
        }
    }

    const frmContact ={       
        nombre:'',
        telefono:'', 
        correo:'',     
        zona:text, 
    }
    const [contact,setContact] = useState(frmContact);
    const handleChange = e => { 
		const {name,value} = e.target;
		setContact({...contact,[name]:value}); 
   };

    const handleSubmit = e =>{
        alert("entro")
	    e.preventDefault();
     
		emailjs.send('default_service','template_ammjr2i', contact,'akEUA8UHaQ1B4AlAf')
		.then((response) => {
				   console.log('SUCCESS!', response.status, response.text);
				   setContact(frmContact);
                   window.location.reload()
                    setTimeout(function(){
                        window.location.reload()
                    }, 1000);
           
		}, (err) => {
				   console.log('FAILED...', err);
		});
   }



   function EnviarFormulario(){

 
    if(contact.nombre ===''){
        alert("Debe de ingresar un nombre !")
    }else if(contact.telefono ===''){
        alert("Debe de ingresar un Telefono !")
    }else if(contact.correo ===''){
        alert("Debe de ingresar un Correo !")
    }else if(contact.zona ===''){
        alert("Debe de ingresar una Zona !")
    }else{
    

       


            const options = {
                method: 'POST',
                url: 'http://18.191.31.74/enviarData',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                params: {
                    
                        email: contact.correo ,
                        nombre: contact.nombre, 
                        telefono: contact.telefono,
                        zona: contact.zona
                
                    
                }
              };


              axios.request(options)
                .then(function (response) {
                    console.log(response.data);
                    window.location.href = "http://enviado2.resvera.com.mx/"
                     
                })
                .catch(function (error) {
                    console.error(error);
                });


    }



    }



    function changeText(id){
        if(id===1){
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="LINEAS DE EXPRESIÓN";
            setText('1 - LINEAS DE EXPRESIÓN')
            setZonatext('LINEAS DE EXPRESIÓN')            
            setSeleccionUno('pointOne selected')
            setSeleccionDos('pointTwo' )
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
        }
        if(id===2){
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="MANCHAS";
            setText('2 - MANCHAS')
            setZonatext('MANCHAS')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo selected')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
        }
        if(id===3){
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="OJERAS";
            setText('3 - OJERAS')
            setZonatext('OJERAS')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree selected')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven ')
        }
        if(id===4){
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="FOXY EYES";
            setText('4 - FOXY EYES')
            setZonatext('FOXY EYES')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour selected')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
        }
        if(id===5){
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="PERFILAMIENTO MANDIBULAR";
            setText('5 - PERFILAMIENTO MANDIBULAR')
            setZonatext('PERFILAMIENTO MANDIBULAR')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive selected')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
        }
        if(id===6){
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="RINOMODELACIÓN";
            setText('6 - RINOMODELACIÓN')
            setZonatext('RINOMODELACIÓN')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix selected')
            setSeleccionSiete('pointSeven')
        }
        if(id===7){
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="RELLENO DE LABIOS";
            setText('7 - RELLENO DE LABIOS')
            setZonatext('RELLENO DE LABIOS')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven selected')
        }
    }


    return(
        <div className="generalContainerLanding">
            <div className="imagenLogo">
                <img src={LogoFooterSVG} alt="Cargando.." />
            </div>
            <section className="sectionOne">

                <div className="header">
                    <div className="footersectionOne">
                        <label className="texone">
                           <img src={LogoFooterSVG} alt="Cargando.." />
                        </label>
                        
                        
                    </div>                                    
                    <div className="footersectionTwo">
                   
                    </div>
                </div>
              
           
            </section>
            <div className="footersectionOne">
            </div>
            
            <section className="sectionCard">
                    ¡TU PIEL ESTÁ <br/>
                    <text>
                   SONRIENDO!
                    </text>
            </section>

            <section className="sectionForm">
            <div className="formContainer">
                <form >
                    

                   
     
                    <div>
                        <label className="subtitleUno"> Nuestro concierge te llamará el dia de hoy<br /> para agendar tu valoracion de piel.</label>
                    </div>


              <div className="Logos">
                <a href="https://api.whatsapp.com/send?phone=525589469744&text=Me%20interesa%20conocer%20mas" target="_blank">
                  <div className="logoUno">
                    <img src={LogoWhat}></img>
                    <label>Habla con tu concierge</label>
                  </div>
                </a>                  
                <a href="http://resvera.com.mx" target="_blank">
                  <div className="logoDos">
                    <img src={LogoWeb}></img>
                    <label>Visita nuestra página web</label>
                  </div>
                </a>
              </div>

<div className="filtro"></div>

                    <div className="agendacita">
                        
                        <label> <label>Agenda tu valoración </label><text> sin costo</text></label>
                        
                    </div>

                    <div>
                        <input type="text" placeholder="Nombre :"  value={contact.nombre}  name="nombre" onChange={handleChange} >
                        </input>
                    </div>


                    <div>
                        <input type="text"  placeholder="Teléfono :" value={contact.telefono}  name="telefono" onChange={handleChange}/>
                    </div>

                    <div>
                        <input type="text"  placeholder="Correo electrónico :" value={contact.correo}  name="correo" onChange={handleChange} />                        
                    </div>

                    <div className="entradasRadios">
                        <label>
                            ¿Qué te gustaria hacer hoy por ti?
                        </label>
                        
                        <div>
                            <div   className={selectOptionUno} onClick={()=> OpcionSeleccionada('Reducir manchas')}></div><text>Reducir manchas</text>
                        </div>
                        <div>
                            <div className={selectOptionDos} onClick={()=> OpcionSeleccionada('Quitar líneas de expresión')}></div><text>Quitar líneas de expresión</text>
                        </div>
                        <div>
                            <div className={selectOptionTres} onClick={()=> OpcionSeleccionada('Eliminar ojeras')}></div><text>Eliminar ojeras</text>
                        </div>
                        <div>
                            <div className={selectOptionCuatro} onClick={()=> OpcionSeleccionada('Otros')}></div><text>Otros</text>
                        </div>
                     
                    </div>



                    </form>
</div>
   
            </section>
        
            <section className="giftcard">
                <button onClick={()=> EnviarFormulario()} >
                    AGENDAR
                </button>
            </section>
            
            <section className="footer">
                <div>
                <label style={{fontStyle:"oblique"}}>Visita nuestra Wellness Beauty Clinic </label>
                    <label  ><a href="https://g.page/Resvera?share" target="_blank" style={{color:"#FFF"}}>Arquímedes 198, Polanco, Miguel Hidalgo, CDMX.</a></label>
                </div>
                <div>
                    <img src={LogoFooterSVG}></img>
                </div>
            </section>
        </div>
    )
}

export default Home;







